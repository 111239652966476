@import "common/styles/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

html {
    font-size: 14px;
}

body {
    font-family: $primary-font;
    font-size: 1.4rem;
}

h1 {
    font-size: 2.4rem;
}

h2 {
    font-size: 2.0rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    font-size: 1.6rem;
}