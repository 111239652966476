footer {
  min-height: 250px;

  .bi {
    font-size: 2.5rem;
  }
}

nav, .navbar-brand {
  font-size: 1.6rem;
}

.nav-link.active {
  text-decoration: underline;
  text-underline-offset: 5px;
}

body {
  background-color: black;
  color: white;
}